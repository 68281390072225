


























































































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

import Form from '@/components/Forms/Form.vue';
import { Context, MapMarker, MapSettings, Office } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import SideBySide from '@/components/Layout/SideBySide.vue';

const Map = () => import('client-website-ts-library/components/Map/Map.vue');

@Component({
  components: {
    Map,
    Form,
    SideBySide,
  },
})
export default class ClientRelationshipManager extends Mixins(View) {
  private formCtx: Context | null = null;

  private office: Office | null = null;

  private mapSettings: MapSettings | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
      this.mapSettings = new MapSettings({
        Markers: [
          new MapMarker({
            Address: `${this.office.AddressLine1} ${this.office.AddressLine2}`,
            Title: 'PROLIST',
            Coordinate: {
              Lat: -16.9344085,
              Lon: 145.774603,
            },
          }),
        ],
      });
    });

    this.formCtx = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.Office,
          Id: Config.Website.Settings!.WebsiteId,
        },
      ],
    };
  }
}
